webcoffee-toast {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .type-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 40px;
    }

    .message {
        flex: 1;
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.72);
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 100px;
        padding: 12px 6px;
    }

    .close-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        align-self: stretch;

        &:hover {
            background: rgba(125, 125, 125, 0.1);
        }
    }
}

.mdc-snackbar.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
        background-color: #25252b;
        border: 1px solid black;
        box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.3), inset 1px 1px 0px 0px rgba(255, 255, 255, 0.06);
        padding: 0;

        .mat-mdc-snack-bar-label {
            padding: 0;
        }
    }
}
